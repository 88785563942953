import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import closeDialogButton from "../../assets/feature/close.svg";
import { useTranslation } from 'react-i18next';


function Otsp() {

	const [state, setState] = useState(true);
	
	const settings = require(process.env.REACT_APP_SETTING_FILE_LOCATION);

	const { t, i18n } = useTranslation();

	const handleClose = () => {
		setState(false)
	}

	const downloadImg = e => {
		fetch(e.currentTarget.href, {
			method: "GET",
			headers: {}
		})
	};

	return (
		<div>
			<Dialog
				className="dialog-wrapper-modal"
				PaperProps={{
					style: {
						background: 'rgb(79 108 130)',
						borderRadius: '36px',
						overflow: 'hidden',
						margin: '20px',
						maxHeight: 'initial'
					},
				}}
				open={state}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<div className="dialog-wrapper otsp-wrapper">
					<DialogTitle disableTypography className="header-info-dialog dialog-title" id="alert-dialog-title">
						<IconButton id="closedialogBtn" onClick={handleClose}>
							<img className="close-dialog-button-image" src={closeDialogButton} alt="X" />
						</IconButton>
					</DialogTitle>
					<DialogContent className="header-info-dialog dialog-desc">
						<div className="otsp-text-wrapper">
							<h3>{t(settings.aamdemocentre.otsp.heading)}</h3>
							<ul>
								{settings.aamdemocentre.otsp.guidelines.map((guideline, i) => {
									return (
										<li key={i}>
											<span>{t(guideline.label)}</span>
										</li>
									)
								})
								}
							</ul>
						</div>
						<div className="images-wrapper">
							<div className="not-allowed-images common">
								<h2>{t(settings.aamdemocentre.otsp.notAllowed.heading)}</h2>
								<div className="row">
									{settings.aamdemocentre.otsp.notAllowed.images.map((imgCounter, i) => {
										return (
											<div className="col">
												<a href={imgCounter} download onClick={e => downloadImg(e)}>
													<span>{t(settings.aamdemocentre.otsp.notAllowed.button)}</span>
													<img src={require('../../assets/experience/' + imgCounter.image).default} alt={imgCounter.title} title={imgCounter.title} />
												</a>
											</div>
										)
									})
									}
								</div>
							</div>
						</div>
					</DialogContent>
				</div>
			</Dialog>
		</div>
	)
}

export default Otsp
