import React from 'react';
import PropTypes from 'prop-types';
import CustomHooks from '../../helpers/CustomHooks';
import QueryString from "../../helpers/QueryString";
import Header from '../shared/header/Header';
import CommonElements from '../shared/commonElements/CommonElements';
import { useTranslation } from 'react-i18next';

const MoreInformationExperiencePage = props => {
	const { t, i18n } = useTranslation();

	const mobileLandscape = CustomHooks.useMedia("(max-width: 1250px)")

	const settings = require(process.env.REACT_APP_SETTING_FILE_LOCATION);

	const queryParameters = window.top.location.href.split("?");
	let selectedFeature, selectedExperience = null;
	if (typeof queryParameters[1] !== "undefined") {
		let parameters = queryParameters[1].split("&");
		selectedFeature = QueryString.getDetailsFromParameters("feature", parameters);
		localStorage.setItem("feature", selectedFeature);
		const selectedExperience_url = QueryString.getDetailsFromParameters("experience", parameters);
		
		/* Joining feature & experience values fetched from URL to form correct key for config's experience key name */
		selectedExperience = selectedFeature+'_'+selectedExperience_url;
		localStorage.setItem("experience", selectedExperience);
	}
	else {
		selectedFeature = localStorage.getItem('feature');
		selectedExperience = localStorage.getItem("experience");
	}

	const skinInfo = settings.aamdemocentre.experiencePage.experiences[selectedExperience].moreInfo;

	const handleNavigation = (e, nextPage, prevRouteFlag) => {
		e.preventDefault();
		if (prevRouteFlag === 'prev') {
			nextPage = '/experience';
		}
		/* we dont want our selected experience's key name from config file to be visible in our URL,
		hence we are splitting the experience key name to get just the designer name to show in the URL */
		const experience_url_param = selectedExperience.split('_')[1];
		nextPage += nextPage === '/experience' ? '?feature='+selectedFeature : '?feature='+selectedFeature+'&experience='+experience_url_param;
		props.history.push(nextPage);
	}

	return (
		<div>
			{mobileLandscape ? null : <Header history={props.history} />}
			<div className="container-wrapper">
				<div className="main-content-container more-info-container">
					
					{/* This creates Page header, Progress Bar, Top & Bottom Navigations */}
					<CommonElements 
						subHeading = {skinInfo.title}
						shortDesc = {skinInfo.shortDescription}
						nextPage = '/designer'
						bottomNavigation = {true}
						handleNavigation = {handleNavigation}
					/>

					<div className="maxWidth-container more-info-content">
						<div className="image-sec"><img className="more-info-image" src={require('../../assets/moreInformation/' + skinInfo.image).default} alt='' /></div>
						<div className="desc-sec"><p className="more-info-image-desc">{t(skinInfo.longDescription)}</p></div>
					</div>
				</div>
			</div>
		</div>
	)
}

MoreInformationExperiencePage.propTypes = {
	history: PropTypes.object.isRequired
}

export default MoreInformationExperiencePage;