import React from 'react';
import PropTypes from 'prop-types';
import CustomHooks from '../../helpers/CustomHooks';
import Header from '../shared/header/Header';
import CommonElements from '../shared/commonElements/CommonElements';
import BoxStructure from '../shared/boxStructure/BoxStructure';
import { updateInformation } from '../../redux/actions/designer'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import progressBarHorizontalImage from '../../assets/progressBar/progressBarStep1_horizontal.svg';

const FeaturePage = props => {
	const { t } = useTranslation();
	
	const mobileLandscape = CustomHooks.useMedia("(max-width: 1250px)");
	
	const settings = require(process.env.REACT_APP_SETTING_FILE_LOCATION);
	const configs = require(process.env.REACT_APP_CONFIG_FILE_LOCATION);
	const settings_features = settings.aamdemocentre.featurePage.features;
	const config_features = configs.aamdemocentre.sequences.features;
	
	const featureList = Object.keys(config_features).map((feature, i) => {
		return {
			keyName: feature,
			title: t(settings_features[feature].title),
			desc: t(settings_features[feature].shortDescription),
			imgPath: require('../../assets/feature/' + settings_features[feature].image).default
		};
	});
	const pageTitle = t(settings.aamdemocentre.landingPage.designer.guidelines[0].label);

	const handleNavigation = (e, nextPage, prevRouteFlag) => {
		e.preventDefault();
		const activeStep = parseInt(e.currentTarget.parentElement.id);

		if (prevRouteFlag === 'prev') {
			nextPage = '/home';
		}		
		else {
			// props.info.feature = featureList[activeStep].keyName;
			// updateInformation(props.info);
			localStorage.setItem("feature", featureList[activeStep].keyName);
			nextPage += '?feature='+featureList[activeStep].keyName;
		}
		props.history.push(nextPage);
	}

	return (
		<div>
			{mobileLandscape ? null : <Header history={props.history} />}
			<div className="container-wrapper">
				<div className="main-content-container feature-container">

					{/* This creates Page header, Progress Bar, Top & Bottom Navigations */}
					<CommonElements 
						pageTitle = {pageTitle}
						nextPage = '/experience'
						progressBar = {true}
						progressBarImage = {progressBarHorizontalImage}
						topNavigation={true}
						handleNavigation = {handleNavigation}
					/>

					{/* Features */}
					<div className='maxWidth-container feat-container'>
						<BoxStructure 
							data={featureList}
							handleNavigation={handleNavigation}
							nextPage='/experience'
							currentPage='feature'
						/>
					</div>

				</div>
			</div>
		</div>
	);
}


const mapStateToProps = state => {
	return {
		info: state.productsInfo.productsData,
		...state
	}
}

FeaturePage.propTypes = {
	history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(FeaturePage);