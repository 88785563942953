import React from 'react';
import ReactDOM from 'react-dom';
import '../src/assets/styles/_main.scss';
import App from '../src/App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import i18n from './i18n'

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
      <React.StrictMode>
      <App />
    </React.StrictMode>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
