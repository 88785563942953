export const QueryString = {
	getDetailsFromParameters
}

function getDetailsFromParameters(key, parameters) {
	let value = '';
	parameters.forEach(parameter => {
		let keyValue = parameter.split('=');
		if (keyValue[0].toUpperCase() === key.toUpperCase()) {
			value = keyValue[1].toLowerCase();
		}
	});
	return value;
}

export default QueryString;
