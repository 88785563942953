import React, { useEffect } from 'react'

function Plugin({
	domain,
	skinFolderName,
	skinStyleUrl,
	skinScriptUrl,
	skinVersion,
	handover
}) {

	// Initial mount
	useEffect(() => {
		let url, css_url;
		if (skinVersion == 'v3') {
			url = skinScriptUrl != undefined ? skinScriptUrl : `${new URL(domain).origin}/PCS/CDN/V3/App/Skins/${skinFolderName}/embed.min.js`;
			css_url = skinStyleUrl != undefined ? skinStyleUrl : `${new URL(domain).origin}/PCS/CDN/V3/App/Skins/${skinFolderName}/embed.min.css`;
		}
		else {
			url = `${new URL(domain).origin}/PCS/CDN/v2/App/Skins/${skinFolderName}/app/embed.min.js`
		}

		const removejscssfile = (filename, filetype) => {
			const targetelement=(filetype=="js")? "script" : (filetype=="css")? "link" : "none" //determine element type to create nodelist from
			const targetattr=(filetype=="js")? "src" : (filetype=="css")? "href" : "none" //determine corresponding attribute to test for
			const allsuspects=document.getElementsByTagName(targetelement)
			for (let i=allsuspects.length; i>=0; i--){ //search backwards within nodelist for matching elements to remove
			if (allsuspects[i] && allsuspects[i].getAttribute(targetattr)!=null && allsuspects[i].getAttribute(targetattr).indexOf(filename)!=-1)
				allsuspects[i].parentNode.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
			}
		}

		// Remove all aamStyleSheets coz otherwise they conflict (mainly for custom GF skins)
		const aamStyleSheets = Object.values(document.styleSheets).filter(ss => ss.title === 'aamStylesheet');
		aamStyleSheets.forEach(ss => removejscssfile(ss.href, "css"));
		
		// Append CSS prior to script (for separate CSS skins)
		if(css_url) {
			const stylesheet = document.createElement('link')
			stylesheet.rel = 'stylesheet'
			stylesheet.href = css_url
			stylesheet.title = 'aamStylesheet'
			document.head.append(stylesheet)
		}

		const loadScript = src => {
			// Remove all aamScripts coz otherwise they conflict
			const aamScripts = Object.values(document.scripts).filter(sc => sc.title === 'aamScript')
			aamScripts.forEach(sc => removejscssfile(sc.src, "js"))
			
			return new Promise((resolve, reject) => {
				const script = document.createElement('script')
				script.type = 'text/javascript'
				script.title = 'aamScript'
				script.onload = resolve
				script.onerror = reject
				script.src = src
				document.head.append(script)
			})
		}

		// Initialise plugin
		loadScript(url).then((res) => {
			//////////// NB: Not the right fix though coz actual issue is coming from Canvas' webpack file so need to fix that 
			// Manually refresh the page coz otherwise multiple Canvas designers conflict
			const reloadCount = sessionStorage.getItem('reloadCount');
			if(reloadCount < 1) {
				sessionStorage.setItem('reloadCount', String(reloadCount + 1));
				window.location.reload();
			} else {
				sessionStorage.removeItem('reloadCount');
			}

			if (skinVersion == 'v2') {
				var designer = new window.AAM({
					el: document.getElementById('designer1'),
					handoverKey: handover
				});
				designer.start()
			}
			else {
				const designer = new window.aam.designer({
					callbacks: {
						onSubmit: function (response) {
							//designerElement.style.display = 'none';
							console.log("appDesignerjs-> onSubmit =======! " + response);
						},
						onError: function (response) {
							console.log(response);
						}
					},
					options: {
						env: new URL(domain).host.split('.')[0],
						detectMobile: true,
						customStyling: false
					}
				});
				designer.start();
			}
		})
	})

	return (
		<div className="plugin-wrapper">
			{skinVersion == 'v3' ? <div id="aam-designer" className='aam-designer-init' data-handoverkey={handover}></div> : <div id="designer1" className='aam-designer-init'></div>}
		</div>
	)
}

export default Plugin
