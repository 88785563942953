import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import moreInfoButton from '../../../assets/experience/moreInfo.svg';
import nextButton from '../../../assets/feature/next.svg';

const BoxStructure = props => {
	const { t, i18n } = useTranslation();
	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = props.data.length;
	const isTouch = 'ontouchstart' in window;
	
	useEffect(
		() => {
			window.addEventListener("keydown", handleKeyPress);
			return () => window.removeEventListener("keydown", handleKeyPress);
		},
		[]
	)

	const handleKeyPress = event => {
		switch (event.key) {
			case 'Escape':
				props.handleNavigation(event, '/home');
				break;
			case 'Backspace':
				if (props.currentPage === 'experience') {
					props.handleNavigation(event, '/feature');
				}
				else {
					props.handleNavigation(event, '/home');
				}
				break;
			case 'ArrowRight':
			case 'd':
				handleNext();
				break;
			case 'ArrowLeft':
			case 'a':
				handleBack();
				break;
			case 'Enter':
				props.handleNavigation(event, props.nextPage, activeStep);
				break;
			case 'm':
				if (props.currentPage === 'experience') {
					props.handleNavigation(event, '/moreinformation', activeStep);
				}
				break;
			default:
				break;
		}
	}

	const handleNext = () => {
		setActiveStep(prevActiveStep => (prevActiveStep + 1) % maxSteps);
	}

	const handleBack = () => {
		setActiveStep(prevActiveStep => (prevActiveStep - 1 + maxSteps) % maxSteps)
	}	

	return (
		<div>
            <div className='data-box-wrap'>
                {
                    props.data.map((item, i) => {
                        return (
                            <div 
								key={i} 
								id={i} 
								data-category={item.keyName} 
								className={"data-box" + (item.galleryName === 'standard' ? ' standard' : '') + (item.keyName === props.activeImgCategory ? ' visible' : '')}>
                                <div onClick={(e) => props.handleNavigation(e, props.nextPage)}>
									<figure>
										<img
											src={item.imgPath}
											alt={item.desc}
											title={item.title}
											className='item-img'
										/>
									</figure>
                                    <p className="heading">{item.title}</p>
                                    <p className="description">{item.desc}</p>
                                </div>
								{ props.currentPage != 'gallery' && props.currentPage != 'images' ?
									<IconButton id="moreInfoBtn" className={isTouch ? 'visible' : ''} onClick={(e) => props.handleNavigation(e, '/moreinformation'+props.currentPage)}>
										<img className="more-info-button-image" src={moreInfoButton} alt="Info" title="More Info." />
									</IconButton>
								: null
								}
                            </div>
                        )
                    })
                }
            </div>
		</div>
	)
}

export default BoxStructure;
