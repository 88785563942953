import { useState, useEffect } from 'react';

export const CustomHooks = {
	useMedia
}

function useMedia(query) {
	let [matches, setMatches] = useState(
		window.matchMedia(query).matches
	);

	useEffect(
		() => {
			let media = window.matchMedia(query);
			let listener = () =>
				setMatches(media.matches);
			media.addListener(listener);
			listener();
			return () => media.removeListener(listener);
		},
		[query]
	);

	return matches;
}

export default CustomHooks;
