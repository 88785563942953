import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ImageCard from './ImageCard';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import QueryString from '../../helpers/QueryString';
import CustomHooks from '../../helpers/CustomHooks';
import ProgressBar from '../shared/progressBar/ProgressBar';
import Header from '../shared/header/Header';
import Footer from '../shared/footer/Footer';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import progressBarHorizontalImage from '../../assets/progressBar/progressBarStep4_horizontal.svg';
import progressBarVerticalImage from '../../assets/progressBar/progressBarStep4_vertical.svg';
import imagehubMoile from "../../assets/thankyou/ipadFrame.png";
import imagehubPad from "../../assets/thankyou/imacFrame.svg";
import imagehubWatch from "../../assets/thankyou/watchFrame.png";
import imagehubApplePay from "../../assets/thankyou/phoneFrame.svg";
import lines from "../../assets/thankyou/lines.svg";
import linesMobile from "../../assets/thankyou/linesMobile.svg";
import emailtemplate from "../../assets/thankyou/emailTemplate.png";
import twitterPost from "../../assets/thankyou/sectionSocialMedia.png";

const ThankyouPage = props => {

	const { t } = useTranslation();
	const settings = require(process.env.REACT_APP_SETTING_FILE_LOCATION);
	const configs = require(process.env.REACT_APP_CONFIG_FILE_LOCATION);

	const selectedFeature = localStorage.getItem('feature');
	const selectedExperience = localStorage.getItem('experience');

	let imagehubdesignurl = null;
	let cardImageId = null;
	let imagehubdcaurl = null;

	useEffect(() => {
		const selectedSkin = props.info.imagedeliverykey
		if (selectedSkin) {
			localStorage.setItem('imagedeliverykey', selectedSkin);
		}
	}, [])

	if (window.top.location != window.self.location) {
		window.top.location.href = window.self.location.origin + window.self.location.pathname + window.self.location.hash + window.self.location.search;
	}

	const mobile = CustomHooks.useMedia('(max-width: 815px)');
	const getImageHubUrl = (url, deliverykey, cardimageid, key_identifier, cardid_identifier, isBaseImage) => {
		url = url.replace(key_identifier, deliverykey);
		url = url.replace(cardid_identifier, cardimageid);
		url += isBaseImage ? '&BaseImageType=Design' : ''; // append BaseImage QS only if selected experience has BaseImage enabled in config.json
		return url;
	}

	const queryParameters = window.top.location.href.split('?');
	if (typeof queryParameters[1] !== 'undefined') {
		let parameters = queryParameters[1].split('&');
		cardImageId = QueryString.getDetailsFromParameters('CardImageId', parameters);

		//getting imagedeliverykey from localstorage
		const imagedeliverykey = localStorage.getItem('imagedeliverykey');

		const key_identifier = settings.aamdemocentre.thankyouPage.keyIdentifier;
		const cardid_identifier = settings.aamdemocentre.thankyouPage.cardidIdentifier;
		const isBaseImage = configs.aamdemocentre.experiences[selectedExperience].baseImage && configs.aamdemocentre.experiences[selectedExperience].baseImage.toLowerCase() === 'true' ? true : false;
		
		imagehubdcaurl = getImageHubUrl(configs.aamdemocentre.imagehub.dca_url, imagedeliverykey, cardImageId, key_identifier, cardid_identifier, isBaseImage);
		imagehubdesignurl = getImageHubUrl(configs.aamdemocentre.imagehub.design_url, imagedeliverykey, cardImageId, key_identifier, cardid_identifier, isBaseImage);
	}

	const handleNavigation = (e, nextPage) => {
		e.preventDefault();
		const experience_url_param = selectedExperience.split('_')[1];
		console.log('local', localStorage);
		console.log('props', props);

		if(nextPage === '/experience') {
			nextPage += '?feature='+selectedFeature;
		}
		if(nextPage === '/designer') {
			nextPage += '?feature='+selectedFeature+'&experience='+experience_url_param;
		}
		props.history.push(nextPage);
	}

	const handleScroll = (e, value) => {
		e.preventDefault();
		if (value) {
			const target = document.getElementById(value)
			let scrollContainer = target;
			do { //find scroll container
				scrollContainer = scrollContainer.parentNode;
				if (!scrollContainer) return;
				scrollContainer.scrollTop += 1;
			} while (scrollContainer.scrollTop == 0);

			let targetY = 0;
			do { //find the top of target relatively to the container
				if (target == scrollContainer) break;
				targetY += target.offsetTop;
			} while (target == target.offsetParent);

			const scroll = function (c, a, b, i) {
				i++; if (i > 30) return;
				c.scrollTop = a + (b - a) / 30 * i;
				setTimeout(function () { scroll(c, a, b, i); }, 20);
			}
			// start scrolling
			scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
		}
	}



	return (
		<div className="blueGradientBGEffect">
			<Header history={props.history} />
			<div className={"thankyou-container " + (selectedFeature.toLowerCase() === 'vertical' ? "verticalProductMode" : '')}>
				<div className="locator">
					<Button id="scrollTopBtn" variant="contained" color="primary" onClick={(e) => handleScroll(e, 'top')}>
						<i className="fa fa-arrow-up fa-big" aria-hidden="true"></i>
					</Button>
					<span className="title">TOP</span>
				</div>
				<section className="card-details-section">

					<aside className="breadcrumb-section">
						<ProgressBar
							progressBar={mobile ? progressBarHorizontalImage : progressBarVerticalImage}
							orientationClass={mobile ? 'horizontal' : 'vertical'}
							featureImage={true}
							designerName={true}
						/>
					</aside>

					<div className="desginer-submitted-wrapper">
						<div className="card-full-view">
							<p className="lead-title">
								<span>{t(settings.aamdemocentre.thankyouPage.pageTitle)}</span>
								<span>{t(settings.aamdemocentre.thankyouPage.heading)}</span>
							</p>
							<ImageCard src={imagehubdesignurl} />
						</div>
					</div>
					{/* Desginer submitted wrapper end here */}

					<div className="navigation-wrapper">
						<div className="navigation-icons">
							<Fab variant="extended" id="changeFeatureButton" className="btn btn-dc" color="primary" onClick={(e) => handleNavigation(e, '/feature')} >
								{t('Change Feature')}
							</Fab>
							<Fab variant="extended" id="changeExperienceButton" className="btn btn-dc" color="primary" onClick={(e) => handleNavigation(e, '/experience')} >
								{t('Change Experience')}
							</Fab>
							<Fab variant="extended" id="changeDesignButton" className="btn btn-dc" color="primary" onClick={(e) => handleNavigation(e, '/designer')} >
								{t('Change Design')}
							</Fab>
						</div>
					</div>
					{/* navigation wrapper end here */}

				</section>
				{/* internal-page-nav-section wrapper */}
				<section className="internal-page-nav-section">
					<div className="page-nav-controls">
						<div className="navigation-panel">
							<div className="column-2">
								<Button id="imageShareBtn" className="button-entity" variant="contained" color="primary" onClick={(e) => handleScroll(e, 'imagesharecontainer')}>
									<i className="fa fa-arrow-down fa-big" aria-hidden="true"></i>
								</Button>
							</div>
							<div className="column-2">
								<Button id="imageHubBtn" className="button-entity" variant="contained" color="primary" onClick={(e) => handleScroll(e, 'imagehubcontainer')}>
									<i className="fa fa-arrow-down fa-big" aria-hidden="true"></i>
								</Button>
							</div>
						</div>

						<div className="card-image-id-wrapper">
							<div className="card-image-id">
								{t('Card Image Id')} : <span>{cardImageId}</span>
							</div>
						</div>
					</div>
				</section>
				{/* internal-page-nav-section wrapper end here */}

				<section className="image-hub-section">
					<div className="imagehub-wrapper">
						<div id="imagehubcontainer" className="imagehub-container">
							<h2 className="imagehub-head is-center">{t(settings.aamdemocentre.thankyouPage.imagehub.heading)}</h2>
							<p className="imagehub-subheading is-center">{t(settings.aamdemocentre.thankyouPage.imagehub.longDescription)}</p>
						</div>
						<div className="imagehub-demos-container">

							<div className="imagehub-card">
								<ImageCard src={imagehubdcaurl} />
							</div>

							<div className="lines-container">
								<div className="imagehub-lines">
									<img src={lines} alt="layout lines" />
								</div>
								<div className="imagehub-lines-mobile">
									<img src={linesMobile} alt="layout lines" />
								</div>
							</div>

							<div className="devices-items-container">
								<div className="mob-desk-wrapper">
									<div className="imagehub-mobile imagehub-sharing">
										<img className="iphone-hub" src={imagehubMoile} alt="iphone X Frame" />
										<ImageCard
											src={imagehubdcaurl}
											className="imagehub-mobile-design cardimagehub-design"
										/>
									</div>

									<div className="imagehub-pad imagehub-sharing">
										<img className="ipad-hub" src={imagehubPad} alt="ipad pro Frame" />

										<ImageCard
											src={imagehubdcaurl}
											className="imagehub-pad-design cardimagehub-design"
										/>
									</div>

									<p className="desc-onlinebanking desc-digitalBanking">{t(settings.aamdemocentre.thankyouPage.imagehub.list.item2)}</p>
								</div>

								<div className="digital-wallet-wrapper">
									<div className="container-of-digital-image">
										<div className="imagehub-watch imagehub-sharing">
											<img className="iwatch-hub" src={imagehubWatch} alt="iwatch Frame" />

											<ImageCard
												src={imagehubdcaurl}
												className="imagehub-watch-design cardimagehub-design"
											/>
										</div>

										<div className="imagehub-pay imagehub-sharing">
											<img className="ipay-hub" src={imagehubApplePay} alt="iphone X Frame" />
											<ImageCard
												src={imagehubdcaurl}
												className="imagehub-pay-design cardimagehub-design"
											/>
										</div>
									</div>

									<div className="label-cont">
										<p className="desc-digiwallets">{t(settings.aamdemocentre.thankyouPage.imagehub.list.item3)}</p>
									</div>
								</div>
							</div>
							{/* Device items container end here */}
						</div>
					</div>
				</section>
				{/* Image hub section end here */}

				<section className="image-share-section">
					<div className="imageshare-container">
						<div id="imagesharecontainer" className="imagehub-container">
							<h2 className="imagehub-head is-center">{t(settings.aamdemocentre.thankyouPage.imageshare.heading)}</h2>
							<p className="imagehub-subheading is-center">{t(settings.aamdemocentre.thankyouPage.imageshare.longDescription)}</p>
						</div>
						<div className="imageshare-demo">
							<div className="email-message step-one">
								<div className="brief-container">
									<h1 className="number-title">1.</h1>
									<span>{t(settings.aamdemocentre.thankyouPage.imageshare.list.item1)}</span>
								</div>
								<div className="email-template imagehub-sharing">
									<img className="accepted-mail" src={emailtemplate} alt="email template" />
									<ImageCard
										src={imagehubdesignurl}
										className="accepted-mail-design cardimagehub-design"
									/>
								</div>
							</div>
							<div className="sharing-message step-two">
								<div className="brief-container">
									<h1 className="number-title">2.</h1>
									<span>{t(settings.aamdemocentre.thankyouPage.imageshare.list.item2)}</span>
								</div>
								<div className="social-sharing imagehub-sharing">
									<img className="social-template-mail" src={twitterPost} alt="email template" />
									<div>
										<ImageCard
											src={imagehubdesignurl}
											className="sharing-image-twitterwall cardimagehub-design"
										/>
										<ImageCard
											src={imagehubdesignurl}
											className="sharing-image-fbwall cardimagehub-design"
										/>
										<ImageCard
											src={imagehubdesignurl}
											className="sharing-image-instawall cardimagehub-design"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				
				<Footer />
			</div>
		</div>
	);
}

ThankyouPage.propTypes = {
	history: PropTypes.object.isRequired
}

const mapStateToProps = state => {
	return {
		info: state.productsInfo.productsData,
		...state
	}
}

export default connect(mapStateToProps)(ThankyouPage);