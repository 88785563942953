import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomHooks from "../../helpers/CustomHooks";
import QueryString from "../../helpers/QueryString";
import Header from "../shared/header/Header";
import CommonElements from '../shared/commonElements/CommonElements';
import Plugin from '../plugin/plugin';
import { updateInformation } from '../../redux/actions/designer';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IconButton from "@material-ui/core/IconButton";
import viewBar from "../../assets/designer/viewBar.svg";
import desktop from "../../assets/designer/desktop.svg";
import landscape from "../../assets/designer/landscape.svg";
import portrait from "../../assets/designer/portrait.svg";
import progressBarHorizontalImage from '../../assets/progressBar/progressBarStep3_horizontal.svg';

const DesignerPage = (props) => {

	const { t, i18n } = useTranslation();

	const mobileLandscape = CustomHooks.useMedia("(max-width: 1250px)");
	
	const configs = require(process.env.REACT_APP_CONFIG_FILE_LOCATION);
	
	const queryParameters = window.top.location.href.split("?");
	let selectedFeature, selectedExperience = null;
	if (typeof queryParameters[1] !== "undefined") {
		let parameters = queryParameters[1].split("&");
		selectedFeature = QueryString.getDetailsFromParameters("feature", parameters);
		localStorage.setItem("feature", selectedFeature);
		const selectedExperience_url = QueryString.getDetailsFromParameters("experience", parameters);
		
		/* Joining feature & experience values fetched from URL to form correct key for config's experience key name */
		selectedExperience = selectedFeature+'_'+selectedExperience_url;
		localStorage.setItem("experience", selectedExperience);

		// props.info.feature = selectedFeature;
		// props.info.experience = selectedExperience;
		// props.dispatch(updateInformation(props.info));
	}
	else {
		selectedFeature = localStorage.getItem('feature');
		selectedExperience = localStorage.getItem("experience");
	}

	const domainName = JSON.parse(process.env.REACT_APP_DOMAIN_URL);
	const environment = configs.aamdemocentre.environment;
	const domainUrl = domainName[environment];
	const [designerMode, setDesignerMode] = useState("desktop");

	// Setting classname for different skins
	if(selectedExperience.indexOf('#') > 0) {
		selectedExperience = selectedExperience.split('#')[0];
	}

	const activeExperience = configs.aamdemocentre.experiences[selectedExperience];
	const skinName = activeExperience.skin.toLowerCase();
	const skinVersion = activeExperience.skinVersion.toLowerCase();

	const skinIndex_url = `${new URL(domainUrl).origin}/PCS/CDN/${skinVersion}/App/Skins/${skinName}/index.html`;
	const customStyle_url = activeExperience.style || undefined;
	const customJS_url = activeExperience.javaScript || undefined;

	const [dotPosition, setDotPosition] = useState("dot-desktop");
	
	const handleDesignerMode = (e, mode, position) => {
		e.preventDefault();
		setDesignerMode((designerMode) => mode);
		setDotPosition((dotPosition) => position);
	};

	const handleNavigation = (e, nextPage, prevRouteFlag) => {
		e.preventDefault();
		if (prevRouteFlag === 'prev') {
			nextPage = '/experience?feature='+selectedFeature;
		}
		props.history.push(nextPage);
	};

	return (
		<div>
			{mobileLandscape ? null : <Header history={props.history} />}
			<div className="container-wrapper">
				<div className="main-content-container designer-container">
					
					{/* This creates Page header, Progress Bar, Top & Bottom Navigations */}
					<CommonElements 
						nextPage='/thankyou'
						progressBar = {true}
						progressBarImage = {progressBarHorizontalImage}
						progressBarFeatureImage={true}
						progressBarDesignerName={true}
						topNavigation={true}
						handleNavigation = {handleNavigation}
					/>

					{/* AAM Designer */}
					<div className="designer-wrapper">
						<div className={['aam-designer-wrap', designerMode, skinName].join(" ")}>
							{
								skinName == 'classicwhite' || skinName == 'iresponsive' ?
								<iframe
									className="designer-iframe"
									src={skinIndex_url+'?handover_key=' + activeExperience.handoverkey}
									title="designer iframe"
								></iframe> :
								<Plugin
									domain={domainUrl}
									skinFolderName={skinName}
									skinStyleUrl={customStyle_url}
									skinScriptUrl={customJS_url}
									skinVersion={skinVersion}
									handover={activeExperience.handoverkey}
								/>
							}
						</div>
					</div>
					
					{ 
						<div className="orientation-container">
							<IconButton className="orientation-btn" id="desktopBtn" onClick={(e) => handleDesignerMode(e, "desktop", "dot-desktop")}>
								<img className="desktop-button-image" src={desktop} alt="X" />
							</IconButton>
							<IconButton className="orientation-btn" id="mobileLandscapeBtn" onClick={(e) => handleDesignerMode(e, "mobile-landscape", "dot-landscape")}>
								<img className="mobile-landscape-button-image" src={landscape} alt="X" />
							</IconButton>
							<IconButton className="orientation-btn" id="mobilePortraitBtn" onClick={(e) => handleDesignerMode(e, "designer-mobile-portrait", "dot-portrait")}>
								<img className="mobile-portrait-button-image" src={portrait} alt="X" />
							</IconButton>
							<img className="view-bar-image" src={viewBar} alt="X" />
							<span className={"dot " + dotPosition}></span>
						</div>
					}
				</div>
			</div>
		</div>
	);
};

DesignerPage.propTypes = {
	history: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
	return {
		info: state.productsInfo.productsData,
		...state
	}
}

export default connect(mapStateToProps)(DesignerPage);
