import React, { Component } from 'react';
import {BrowserRouter as Router,Route, Switch, Redirect } from "react-router-dom";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {orange} from '@material-ui/core/colors';
import HomePage from './components/home/HomePage';
import FeaturePage from './components/feature/FeaturePage';
import ExperiencePage from './components/experience/ExperiencePage';
import MoreInformationExperiencePage from './components/moreInformationExperience/MoreInformationExperiencePage';
import MoreInformationFeaturePage from './components/moreInformationFeature/MoreInformationFeaturePage';
import DesignerPage from './components/designer/DesignerPage';
import ThankyouPage from './components/thankyou/ThankyouPage';
import GalleryPage from './components/gallery/GalleryPage';
import GalleryImagesPage from './components/galleryImages/GalleryImagesPage';
import FaqPage from './components/faq/FaqPage';
import { createBrowserHistory } from "history";
import { Provider } from 'react-redux'
import store from './redux/store';

const theme = createMuiTheme({
  typography: {
  useNextVariants: true,
},
  palette: {
    primary: orange,
  },
});

const history = createBrowserHistory();

class App extends Component {
  render() {
    return (
      <div className="App demo-center-container">
          <Provider store={store}>  
              <Router basename={'/democenter'} history={history}>
                <div>
                  <MuiThemeProvider theme={theme}>
                      <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route exact path="/home" component={HomePage} />
                        <Route exact path="/feature" component={FeaturePage} />
                        <Route exact path="/experience" component={ExperiencePage} />
                        <Route exact path="/moreInformationExperience" component={MoreInformationExperiencePage} />
                        <Route exact path="/moreInformationFeature" component={MoreInformationFeaturePage} />
                        <Route exact path="/designer" component={DesignerPage} />
                        <Route exact path="/thankyou" component={ThankyouPage} />
                        <Route exact path="/gallery" component={GalleryPage} />
                        <Route exact path="/images" component={GalleryImagesPage} />
                        <Route exact path="/faq" component={FaqPage} />
                        <Redirect to="/" />
                    </Switch>
                  </MuiThemeProvider>
                </div>
              </Router>
          </Provider>
      </div>
    );
  }
}

export default App;
