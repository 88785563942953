import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import closeDialogButton from "../../../assets/feature/close.svg";
import { useTranslation } from 'react-i18next';

/* HR's Translation work */
// import LanguageSelector from '../languageSelector/LanguageSelector';

const Header = props => {
	const [open, setOpen] = React.useState(false);
	const settings = require('../' + process.env.REACT_APP_SETTING_FILE_LOCATION);
	const { aamdemocentre: { header } } = settings;
	const { t, i18n } = useTranslation();

	const handleNavigation = (e, nextPage) => {
		e.preventDefault();
		props.history.push(nextPage)
	}

	const handleClickOpen = () => {
		setOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
	}

	const handleWebLink = () => {
		const allAboutmeLink = "https://www.thalesgroup.com/en/markets/digital-identity-and-security/banking-payment/issuance/perso/artwork-services/all-about-me";
		window.open(allAboutmeLink, '_blank');
	}
	return (
		<div className='header-container-main' id="top">
			{/* <LanguageSelector /> */}
			<div className='header-main-background'>
				<div className="flex-wrap">
					<div className='allaboutme-area' onClick={(e) => handleNavigation(e, '/home')}>
						<p className="header-text">{t(header.logo.text1)}</p>
						<p className="header-text bold-text">{t(header.logo.text2)}</p>
					</div>
					<div className="header-more-info-container">
						<a href="javascript:void('0')" onClick={handleClickOpen}>About Us</a>
						<a href="javascript:void('0')" onClick={(e) => handleNavigation(e, '/faq')}>FAQ</a>

						<Dialog
							PaperProps={{
								className:'about-us-modal',
							}}
							open={open}
							onClose={handleClose}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<div className="dialog-wrapper">
								<DialogTitle disableTypography className="header-info-dialog dialog-title" id="alert-dialog-title">
									<h2 dangerouslySetInnerHTML={ { __html: header.about.title } }></h2>
									<IconButton id="closedialogBtn" onClick={handleClose}>
										<img className="close-dialog-button-image" src={closeDialogButton} alt="X" />
									</IconButton>
								</DialogTitle>
								<DialogContent className="header-info-dialog dialog-desc">
									<DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={ { __html: header.about.description } }></DialogContentText>
								</DialogContent>
								<DialogActions className="header-info-dialog visit-button">
									<Fab variant="extended" color="primary" onClick={handleWebLink}>
										{t(header.about.ctaText)}
									</Fab>
								</DialogActions>
							</div>
						</Dialog>
					</div>
				</div>
			</div>
		</div>
	);
}

Header.propTypes = {
	history: PropTypes.object.isRequired
}

export default Header;