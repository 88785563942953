import React from 'react';
import PropTypes from 'prop-types';
import CustomHooks from '../../helpers/CustomHooks';
import Header from '../shared/header/Header';
import CommonElements from '../shared/commonElements/CommonElements';
import BoxStructure from '../shared/boxStructure/BoxStructure';
import { updateInformation } from '../../redux/actions/designer'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const GalleryPage = props => {
	const { t } = useTranslation();
	
	const mobileLandscape = CustomHooks.useMedia("(max-width: 1250px)");
	
	const settings = require(process.env.REACT_APP_SETTING_FILE_LOCATION);
	const settings_galleryPage = settings.aamdemocentre.galleryPage;
	const settings_galleries = settings_galleryPage.galleries;
	
	const galleryList = Object.keys(settings_galleries).map((item, i) => {
		return {
			keyName: item,
			title: t(settings_galleries[item].title),
			desc: t(settings_galleries[item].shortDescription),
			imgPath: require('../../assets/feature/' + settings_galleries[item].image).default
		};
	});
	
	const pageTitle = t(settings.aamdemocentre.landingPage.galleries.guidelines[0].label);
	const shortDescription = settings_galleryPage.shortDescription;

	const handleNavigation = (e, nextPage, prevRouteFlag) => {
		e.preventDefault();
		const activeStep = parseInt(e.currentTarget.parentElement.id);

		if (prevRouteFlag === 'prev') {
			nextPage = '/home';
		}		
		else {
			// props.info.gallery = galleryList[activeStep].keyName;
			// updateInformation(props.info);
			localStorage.setItem("gallery", galleryList[activeStep].keyName);
			nextPage += '?gallery='+galleryList[activeStep].keyName;
		}
		props.history.push(nextPage);
	}

	return (
		<div>
			{mobileLandscape ? null : <Header history={props.history} />}
			<div className="container-wrapper">
				<div className="main-content-container gallery-container">

					{/* This creates Page header, Progress Bar, Top & Bottom Navigations */}
					<CommonElements 
						pageTitle = {pageTitle}
						shortDesc = {shortDescription}
						nextPage = '/images'
						topNavigation={true}
						handleNavigation = {handleNavigation}
					/>

					{/* Galleries */}
					<div className='maxWidth-container gal-container'>
						<BoxStructure 
							data={galleryList}
							handleNavigation={handleNavigation}
							nextPage='/images'
							currentPage='gallery'
						/>
					</div>

				</div>
			</div>
		</div>
	);
}


const mapStateToProps = state => {
	return {
		info: state.productsInfo.productsData,
		...state
	}
}

GalleryPage.propTypes = {
	history: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(GalleryPage);