import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
const ProgressBar = props => {

	const { t, i18n } = useTranslation();
	const settings = require('../' + process.env.REACT_APP_SETTING_FILE_LOCATION);

	const selectedFeature = localStorage.getItem("feature");
	let selectedExperience = localStorage.getItem("experience");
	let featureImgName = null;
	let designerName = null;
	if(selectedExperience && selectedExperience.indexOf('#') > 0) {
		selectedExperience = selectedExperience.split('#')[0];
	}
	if (props.designerName) { designerName = settings.aamdemocentre.experiencePage.experiences[selectedExperience].title; }
	if (props.featureImage) { featureImgName = settings.aamdemocentre.featurePage.features[selectedFeature].image; }

	return (
		<div className={"progress-bar " + props.orientationClass}>
			<p className="progress-bar-feature-text">
				{t(settings.aamdemocentre.progressBar.step1.label)}
			</p>
			{
				props.featureImage ? <img className="progress-bar-feature-img" src={require("../../../assets/feature/" + featureImgName).default} alt='' />
					: null
			}
			<p className="progress-bar-experience-text">
				{t(settings.aamdemocentre.progressBar.step2.label)}
			</p>
			{
				props.designerName ? <p className="progress-bar-designer-name"> {designerName}</p>
					: null
			}
			<p className="progress-bar-design-text">
				{t(settings.aamdemocentre.progressBar.step3.label)}
			</p>
			<p className="progress-bar-submission-text">
				{t(settings.aamdemocentre.progressBar.step4.label)}
			</p>
			<img src={props.progressBar} className="progress-bar-progress-img" alt='' />
		</div>
	)
}

ProgressBar.propTypes = {
	progressBar: PropTypes.string,
	designerName: PropTypes.bool,
	featureImage: PropTypes.bool
}

export default ProgressBar;
