import React from 'react';
import PropTypes from 'prop-types';
import CustomHooks from '../../helpers/CustomHooks';
import Header from '../shared/header/Header';
import CommonElements from '../shared/commonElements/CommonElements';
import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";

const FaqPage = props => {
	const { t, i18n } = useTranslation();
	
	const mobileLandscape = CustomHooks.useMedia("(max-width: 1250px)")

	const settings = require(process.env.REACT_APP_SETTING_FILE_LOCATION);
	
	const settings_faq = settings.aamdemocentre.faqPage;
	const pageTitle = t(settings_faq.heading);

	const handleNavigation = (e, nextPage) => {
		e.preventDefault();
		props.history.push(nextPage)
	}

	return (
		<div>
			{mobileLandscape ? null : <Header history={props.history} />}
			<div className="container-wrapper">
				<div className="main-content-container faq-container">

					{/* This creates Page header, Progress Bar, Top & Bottom Navigations */}
					<CommonElements 
						pageTitle = {pageTitle}
						nextPage='/home'
						topNavigation={true}
						handleNavigation = {handleNavigation}
					/>

					<div className="maxWidth-container faq-content">
						{settings_faq.questions.map((item, i) => {
							return (
							<div key={i} className="faq-item">
								<span className='list-counter'>{i+1}.</span>
								<Accordion className="accord-item">
									<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									>
									<Typography className='question-block'>{item.question}</Typography>
									</AccordionSummary>
									<AccordionDetails>
									<Typography className='answer-block'>
										<p dangerouslySetInnerHTML={ { __html: item.answer } }></p>
									</Typography>
									</AccordionDetails>
								</Accordion>
							</div>
							)
						})
						}
					</div>
				</div>
			</div>
		</div>
	)
}

FaqPage.propTypes = {
	history: PropTypes.object.isRequired
}

export default FaqPage;