import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
import translationEN from './locales/de-DE/translation.json';
import translationDE from './locales/de-DE/translation.json';
import translationHI from './locales/hi-IN/translation.json';
import translationUR from './locales/ur-UR/translation.json';

const resources = {
    "en": {
        translation: translationEN
      },
      "de-DE": {
        translation: translationDE
      },
      'hi-IN': {
        translation: translationHI
      },
      "ur-UR": {
        translation: translationUR
    }
};

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en',
    debug: false, // Always disable if done with the development
    detection: options,
    react : {
      useSuspense : false
    },
    resources,
    lng: "en",
    keySeparator: '.', // allow dot in case of key pair
    interpolation: {
      escapeValue: false, // react already safes from xss
      formatSeparator: ','
    }
  });

  export default i18n;