import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

const ImageCard = props => {
	const Imagesrc = props.src;
	const [loaded, setLoaded] = useState(false);

	useEffect(
		() => {
			document.getElementById('imageCard').addEventListener('load', function () {
				setLoaded(true);
			})
		}, [loaded]
	)

	return (
		<div>
			{
				loaded === true ? <CircularProgress className={props.className} color="secondary" />
					: null
			}
			<img id="imageCard" className={props.className} src={Imagesrc} alt="Designer Card" />
		</div>
	)
}

ImageCard.propTypes = {
	src: PropTypes.string,
	className: PropTypes.string
}

export default ImageCard;
