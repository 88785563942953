import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProgressBar from '../progressBar/ProgressBar';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import backButton from '../../../assets/feature/back.svg';

const CommonElements = props => {
	const { t, i18n } = useTranslation();
	const [open, setOpen] = React.useState(false);
	const settings = require('../' + process.env.REACT_APP_SETTING_FILE_LOCATION);
	const { aamdemocentre: { header } } = settings;

	return (
		<div>
			{
				props.pageTitle || props.subHeading || props.shortDesc ?
				<div className="page-heading-container">
					<p className="page-heading" dangerouslySetInnerHTML={ { __html: props.pageTitle } }></p>
					<p className="page-subHeading" dangerouslySetInnerHTML={ { __html: props.subHeading} }></p>
					<p className="page-description" dangerouslySetInnerHTML={ { __html: props.shortDesc } }></p>
				</div>
				: null
			}

			{
				props.progressBar ?
				<div className="progressBar-container">
					<ProgressBar 
						progressBar={props.progressBarImage} 
						orientationClass='horizontal' 
						featureImage={props.progressBarFeatureImage}
						designerName={props.progressBarDesignerName}
					/>
				</div>
				: null
			}

			{
				props.topNavigation ?
				<div className="top-nav-container">
					<IconButton id="backBtn" onClick={(e) => props.handleNavigation(e, props.nextPage, 'prev')}>
						<img className="back-button-image" src={backButton} alt="Back" />
					</IconButton>
				</div>
				: null
			}

			{
				props.bottomNavigation ?
				<div className="action-buttons-container">
					<Fab variant="extended" id="backBtn" color="secondary" onClick={(e) => props.handleNavigation(e, props.nextPage, 'prev')} >
						{t('Back')}
					</Fab>
					<Fab variant="extended" id="nextBtn" color="primary" onClick={(e) => props.handleNavigation(e, props.nextPage)} >
						{t('Next')}
					</Fab>
				</div>
				: null
			}
		</div>
	);
}

CommonElements.propTypes = {
	history: PropTypes.object.isRequired
}

export default CommonElements;