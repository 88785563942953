import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import Header from '../shared/header/Header';
import Footer from '../shared/footer/Footer';
import one from '../../assets/home/1.svg'
import two from '../../assets/home/2.svg'
import three from '../../assets/home/3.svg'
import illustration from '../../assets/home/illustration.png'
import iconFeature from '../../assets/home/iconExperience.svg'
import iconExperience from '../../assets/home/iconFeatures.svg'
import cards from '../../assets/home/cards.svg'
import galleryIcon from '../../assets/home/galleries.svg'
import imageIcon from '../../assets/home/images.svg'

import { useTranslation } from 'react-i18next';

const HomePage = props => {

	const settings = require(process.env.REACT_APP_SETTING_FILE_LOCATION);

	const handleNavigation = (e, nextPage) => {
		e.preventDefault();
		props.history.push(nextPage);
	}

	const { aamdemocentre: { landingPage } } = settings

	const { t, i18n } = useTranslation();

	return (
		<div className="homepage blueGradientBGEffect">
			<Header history={props.history} />
			<div className='landing-container'>
				<div className='maxWidth-container landing-text-container'>
					<div className="heading-banner-wrap">
						<div className="heading-container">
							<p className='landing-heading first-text-line' dangerouslySetInnerHTML={ { __html: landingPage.heading1 } }></p>
							<p className='landing-heading second-text-line' dangerouslySetInnerHTML={ { __html: landingPage.heading2 } }></p>
						</div>
						<div className="banner-container">
							<img className="illustration" src={illustration} alt='' />
						</div>
					</div>
					<div className="steps-wrap">
						<div className='design-steps'>
							<h3>{landingPage.designer.heading}</h3>
							<div className='flexList'>
								<ul className='steps-list'>
									{landingPage.designer.guidelines.map((guideline, i) => {
										return (
											<li key={i}>
												<div className="step-item">
													<div className="steps-label">
														<img className="step-number" src={i == 0 ? one : i == 1 ? two : three} alt={guideline.label} />
														<span className="steps-text">
															{t(guideline.label)}
														</span>
														<span className="steps-text short">
															{t(guideline.shortLabel)}
														</span>
													</div>
													<div className="step-image">
														<figure className="step-figure">
															<img id={"stepImage" + i} className="step-image" src={i == 0 ? iconExperience : i == 1 ? iconFeature : cards} alt={guideline.label} />
														</figure>
													</div>
												</div>
											</li>
										)
									})
									}
								</ul>
								<div className='button-container'>
									<Fab variant="extended" id="startBtn" color="primary" onClick={(e) => handleNavigation(e, '/feature')}>
										{t(landingPage.button)}
									</Fab>
								</div>
							</div>
						</div>
						<div className='gallery-steps'>
							<h3>{landingPage.galleries.heading}</h3>
							<div className='flexList'>
								<ul className='steps-list'>
									{landingPage.galleries.guidelines.map((guideline, i) => {
										return (
											<li key={i}>
												<div className="step-item">
													<div className="steps-label">
														<img className="step-number" src={i == 0 ? one : i == 1 ? two : three} alt={guideline.label} />
														<span className="steps-text">
															{t(guideline.label)}
														</span>
														<span className="steps-text short">
															{t(guideline.shortLabel)}
														</span>
													</div>
													<div className="step-image">
														<figure className="step-figure">
															<img id={"stepImage" + i} className="step-image" src={i == 0 ? galleryIcon : imageIcon} alt={guideline.label} />
														</figure>
													</div>
												</div>
											</li>
										)
									})
									}
								</ul>
								<div className='button-container'>
									<Fab variant="extended" id="startBtn" color="primary" onClick={(e) => handleNavigation(e, '/gallery')}>
										{t(landingPage.button)}
									</Fab>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

HomePage.propTypes = {
	history: PropTypes.object.isRequired
}


export default HomePage;
