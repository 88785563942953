import { UPDATE_INFO } from '../actions/types'

const initialState = {
    productsData : { 
      selectedSkin : '',
      feature : '',
      imagedeliverykey : '',
      handoverkey : ''
    }
}

function Products(state = initialState, action) {
    switch(action.type) {
      case UPDATE_INFO:
          return {
            ...state,
            productsData : action.payload
          }
      default:
        return state
    }
}

export default Products 