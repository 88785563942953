import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import closeDialogButton from "../../../assets/feature/close.svg";
import thalesLogo from '../../../assets/header/thalesLogo.svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
	const { t, i18n } = useTranslation();
	const settings = require('../' + process.env.REACT_APP_SETTING_FILE_LOCATION);
	const [open, setOpen] = React.useState(false);

	const handleWebsiteClick = () => {
		setOpen(true);
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	}

	const copyToClipboard = e => {
		e.preventDefault();
		let textArea = document.createElement("textarea");
		textArea.value = window.location.href;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
		handleWebsiteClick();
	}

	return (
		<div className="footer-container">
			<div className="share-wrapper">
				<p className="desktop-footer-text">{t(settings.aamdemocentre.footer.copyrightText)}</p>
			</div>
			<div className="copyright-container">
				<img className='footer-gemalto-logo-image' src={thalesLogo} alt={'Thales'} />

			</div>
			<div className="copy-wrapper">
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}

					message={<span id="message-id">{t(settings.aamdemocentre.footer.clipboardText)}</span>}

					action={
						<IconButton
							key="close"
							aria-label="close"
							color="inherit"
							onClick={handleClose}
						>
							<img className="close-button-image" src={closeDialogButton} alt="X" />
						</IconButton>
					}
				/>
			</div>
		</div>
	);
}

export default Footer;
